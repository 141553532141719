<template>
  <div v-if="hasSubcategories" class="section mt-2">
    <ul role="list" class="commercial row row--largegap">
      <li v-for="(cat, i) in orderedSubcategories" :key="i" class="col-12 col-md-3 col-lg-24">
        <a :href="cat.urlKey">
          <v-lazy-image v-if="cat.image" :src="image(cat.image)" :alt="cat.name" />
          <h2 class="commercial__absolute-title">{{ cat.name }}</h2>
          <img src="/images/dr-max.svg" alt="dr-max" class="logo">
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { image } from '@nsf/utils/UrlUtils.js'

export default {

  inject: [
    'subcategories',
  ],

  props: {
    className: {
      type: String,
      default: '',
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    hasSubcategories() {
      return !!this.subcategories?.length
    },

    orderedSubcategories() {
      if (!this.hasSubcategories) {
        return []
      }
      const ordered = [...this.subcategories].sort((a, b) => a.position - b.position)
      return this.displayedCount ? this.mapData(ordered.slice(0, this.displayedCount)) : this.mapData(ordered)
    },

    displayedCount() {
      if (this.item.settings?.count) {
        return Number.parseInt(this.item.settings?.count)
      }

      if (this.data?.children[0]?.count) {
        return Number.parseInt(this.data.children[0].count)
      }

      if (this.className?.startsWith('subCategoryBoxes-widget-')) {
        const count = Number.parseInt(this.className.slice('subCategoryBoxes-widget-'.length))
        return count > 0 ? count : null
      }
      return null
    },

  },

  methods: {
    image(catImage) {
      return image({
        m2: catImage,
        w: 450,
        h: 275,
      })
    },

    urlKeyFromPath(cat) {
      const path = cat.url_path || cat.urlPath
      if (!path) {
        return '/'
      }
      return path.startsWith('/') ? path : `/${path}`
    },

    mapData(array) {
      return array.map((category) => {
        if (Object.prototype.hasOwnProperty.call(category, 'name')) {
          return {
            image: `/media/catalog/category/${category.image}`,
            name: category.name,
            urlKey: this.urlKeyFromPath(category),
          }
        }
        return {
          image: category.icon,
          name: category.title,
          urlKey: category.urlKey,
        }
      })
    },
  },

}
</script>
