<template>
  <div class="input-wrapper">
    <input
      ref="input"
      v-bind="$attrs"
      :value="value"
      :type="type"
      :role="role"
      class="input"
      :class="[classes, validClasses, { 'leading-icon': leadingIcon, 'is-shifted': isShifted }]"
      v-on="listeners"
    >
    <div v-if="isValid !== null" class="validity-icon" :class="[ validClasses, { 'is-shifted': isShifted }]">
      <svg
        v-if="isValid"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use xlink:href="@nsf/ui-library/assets/icons/icons.svg#check-circle-outline" />
      </svg>

      <svg
        v-else
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <use xlink:href="@nsf/ui-library/assets/icons/icons.svg#error-outline" />
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputAtom',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number, Boolean],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    role: {
      type: String,
      default: '',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
    leadingIcon: {
      type: Boolean,
      default: false,
    },
    trailingIcon: {
      type: Boolean,
      default: false,
    },
    isShifted: {
      type: Boolean,
      default: false,
    },
    classes: {
      type: String,
      default: '',
    },
  },

  computed: {
    listeners() {
      return {
        ...this.$listeners,
        input: this.onInput,
        keyup: this.onKeyup,
        change: this.onChange,
        focus: () => {},
      }
    },

    validClasses() {
      return {
        'is-valid': this.isValid === true,
        'is-invalid': this.isValid === false,
      }
    },
  },
  methods: {
    onInput(event) {
      this.$emit('input', event.target.value)
    },

    onKeyup(event) {
      this.$emit('keyup', this)

      if (event.key === 'Tab') {
        this.onFocus()
      }
    },

    onFocus() {
      this.$emit('focus')
    },

    onChange(event) {
      this.$emit('change', event.target.checked)
    },
  },
}
</script>

<style scoped lang="scss">
/* stylelint-disable max-nesting-depth */
.input-wrapper {
  position: relative;
}

.validity-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);

  &.is-shifted {
    right: 40px;
  }

  &.is-valid {
    color: $green-500;
  }

  &.is-invalid {
    color: $red-500;
  }
}

.input {
  @include body-medium;
  width: 100%;
  height: 40px;
  padding: 7px 36px 7px 8px;
  letter-spacing: 0.05px;
  color: $text-black-primary;
  border: 1px solid $gray-300;
  border-radius: 5px;
  background-color: $text-white-primary;
  box-shadow: 0 4px 5px 1px #0000000a inset, 0 2px 3.5px 0 #00000005 inset, 0 1px 1.5px 0 #0000000f inset;
  font-family: inherit;

  &.leading-icon {
    padding-left: 40px;
  }

  &.is-shifted {
    padding-right: 68px;
  }

  &::placeholder {
    @include body-medium;
    color: $text-black-tertiary;
  }

  &:active {
    color: $text-black-primary;
    border-color: $blue-500;
  }

  &:focus-visible {
    color: $text-black-primary;
    border-color: $blue-500;
    outline: 2px solid $yellow-200;
    outline-offset: 2px;
  }

  &:disabled {
    background: $gray-50;
  }

  &.is-valid {
    color: $green-800;
    border-color: $green-500;
    box-shadow: 0 4px 5px 1px #356b0914 inset, 0 2px 3.5px 1px #356b090a inset, 0 1px 1.5px 1px #356b0929 inset;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: $green-800;
    }
  }

  &.is-invalid {
    color: $red-800;
    border-color: $red-500;
    box-shadow: 0 4px 5px 1px #e4012c14 inset, 0 2px 3.5px 0 #e4012c0a inset, 0 1px 1.5px 0 #e4012c29 inset;
  }
}
</style>
