/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import { isEmpty } from '@nsf/core/Utils.js'
import { Builder } from '@nsf/base/builders/Builder.js'
import { getBrandByOptionId } from '@nsf/brands/repositories/BrandRepository.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import {
  getProductDetailById, getProductsBySkus,
} from '@nsf/catalog/repositories/ProductRepository.js'
import { getCategoriesByIds } from '@nsf/catalog/repositories/CategoryRepository.js'
import { getProductAttachmentsByProductId } from '@nsf/catalog/repositories/ProductAttachmentRepository.js'
import getProductGroup from '@nsf/catalog/repositories/ProductGroupRepository.js'
import { getRatingsByProductIds } from '@nsf/catalog/repositories/ReviewRepository.js'
import { getRatingsByProductIds as getRatingsByProductIdsV2 } from '@nsf/catalog/repositories/ReviewServiceRepository.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'
import { mapStarsToReview } from '@nsf/catalog/mappers/ReviewMapper.js'

const {
  public: {
    reviewServiceEnabled,
  },
} = useRuntimeConfig()

const {
  catalog: {
    category: {
      root,
    },
    product: {
      reviewsEnabled,
    },
  },
} = useAppConfig()

// eslint-disable-next-line import/prefer-default-export
export class ProductBuilder extends Builder {
  async fetchProduct() {
    const { product } = await getProductDetailById(this.id())

    return {
      ...product,
      ...(!product?.id && { statusCode: 404 }),
    }
  }

  async fetchProductAttachments() {
    const { productAttachments } = await getProductAttachmentsByProductId(this.id())

    return { _productAttachments: productAttachments }
  }

  async fetchCategories({ categoryIds }) {
    const { categories } = await getCategoriesByIds(categoryIds)

    return { _categories: categories }
  }

  #hasBundles(bundles) {
    if (isEmpty(bundles)) {
      return false
    }
    if (this.query('sort')) {
      return false
    }
    if (this.query('filter')) {
      return false
    }
    if (this.query('search')) {
      return false
    }
    return !this.query('range')
  }

  async fetchBundleProducts({ bundles = [] }) {
    if (!this.#hasBundles(bundles)) {
      return
    }

    const skus = bundles.flatMap((bundle) => bundle.items.map((product) => product.sku))
      .filter((item) => !!item)

    const { products } = await getProductsBySkus(skus)

    bundles.forEach((bundle) => {
      // eslint-disable-next-line no-param-reassign
      bundle._items = bundle.items.map((item) => products.find((p) => (p.sku === item.sku)))
    })

    return { _bundles: bundles }
  }

  async #fetchRelatedProducts(product) {
    const links = product.productLinks
    const relatedSkus = links?.map((e) => e.linkedProductSku) || []
    const data = {
      _cleverAlternatives: [],
      _crossell: [],
      _related: [],
      _upsell: [],
    }

    if (!relatedSkus.length) {
      return data
    }
    // get products by links and add the link type to it
    const { products } = await getProductsBySkus(relatedSkus, {
      callback: (e) => e.whereIn('drmax_pim_status', ['Available', 'Special sale off']),
      size: 40,
    })
    const linkedProducts = products.map((e) => {
      const link = links.find((f) => f.linkedProductSku === e.sku).linkType
      return {
        loaderType: link,
        loaderLabel: 'Magento',
        ...e,
      }
    })

    // group magento products by link type
    const groupedProducts = linkedProducts.reduce((result = {}, prod) => {
      const key = prod.loaderType

      if (!result[key]) {
        // eslint-disable-next-line no-param-reassign
        result[key] = []
      }
      result[key].push(prod)
      return result
    }, {})

    data._cleverAlternatives = groupedProducts.clever_alternative ?? []
    data._upsell = groupedProducts.upsell ?? []
    data._crossell = groupedProducts.crosssell ?? []
    data._related = groupedProducts.related ?? []

    return { ...data }
  }

  async #fetchProductGroup() {
    const { productGroup } = await getProductGroup(this.id())

    return { _productGroup: productGroup }
  }

  async #fetchRating(product) {
    if ((!reviewsEnabled || !product.rating) && !reviewServiceEnabled) {
      return {}
    }

    if (reviewServiceEnabled) {
      const response = await getRatingsByProductIdsV2([this.id()])
      const review = response?.reviews?.[0]
      return {
        _stars: {
          stars: mapStarsToReview(review?.reviews_count),
        },
        _count: review?.reviews_count?.total_reviews,
        _avg: review?.average_rating,
      }
    }

    const { ratings } = await getRatingsByProductIds([this.id()], true)

    return { _stars: ratings[0] ?? null }
  }

  async fetchBrand({ drmaxBrand }) {
    if (!drmaxBrand) {
      return
    }
    const { brand } = await getBrandByOptionId(drmaxBrand)

    return { _brand: brand }
  }

  buildHierarchicalCategories({
    drmaxMainCategoryId = 0,
    _categories = [],
  }) {
    const mainCategory = _categories.find(({ id }) => id === drmaxMainCategoryId) // Category specified by id
      || _categories.sort((a, b) => b.path?.length - a.path?.length)[0] // Category with longest path

    const path = mainCategory?.path ?? ''

    const ids = path
      .split('/')
      .map((id) => parseInt(id))
      .filter((id) => id !== root.id)
      .filter((id) => id !== this.id())

    const hierarchicalCategories = _categories
      .filter((category) => ids.includes(category.id)) // Filter out categories not in the 'ids' array
      .sort((
        a, b,
      ) => ids.indexOf(a.id) - ids.indexOf(b.id)) // Sort categories as they appear in the 'ids' array

    return {
      _mainCategory: mainCategory,
      _hierarchicalCategories: hierarchicalCategories,
    }
  }

  async load() {
    await super.load([
      this.fetchProduct,
      this.fetchProductAttachments,
      this.#fetchProductGroup,
    ])

    await super.load([
      this.#fetchRating,
      this.#fetchRelatedProducts,
      this.fetchCategories,
      this.fetchBundleProducts,
      this.fetchBrand,
    ])

    await super.load([this.buildHierarchicalCategories])
  }
}
