<template>
  <v-lazy-hydrate when-idle>
    <v-popular-brands
      v-if="brands.length"
      :brands="brands"
    />
  </v-lazy-hydrate>
</template>

<script>
import logger from '@nsf/base/logger.js'

export default {
  name: 'PopularBrandsWidget',
  components: {
    'v-popular-brands': () => import('../components/PopularBrands.vue'),
  },

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },

  computed: {
    brands() {
      try {
        return JSON.parse(this.item.settings?.json || this.data.children[0]?.value)
      } catch (e) {
        logger.withTag('PopularBrandsWidget').error(e.message)
      }
      return []
    },
  },
}
</script>
