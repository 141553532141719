const pickupInstructions = [
  {
    icon: 'sms',
    fragments: [{ text: 'Vyzvednutí bude možné po obdržení SMS.' }],
  },
  {
    icon: 'pharmacy',
    fragments: [{ text: 'Objednávka na vás bude čekat 3 dny.' }],
  },
]

export default {
  orderLabel: 'Objednávka do boxu',
  unavailablePickupLabel: 'Do boxu nelze objednat',
  selectedPlaceLabel: 'Vybraný box',
  confirmButtonLabel: 'Vybrat box',
  getTitle() {
    return this.title
  },
  getSubtitle() {
    return [this.location.street, this.location.city].filter(Boolean).join(', ')
  },
  getInstructions() {
    return {
      place: this.location.description
        ? [
            {
              icon: 'info',
              fragments: [{ text: this.location.description }],
            },
          ]
        : [],
      pickup: pickupInstructions,
    }
  },
  pins: {
    enabled: 'pin-boxes-enabled.svg',
    disabled: 'pin-boxes-disabled.svg',
    selected: 'pin-boxes-selected.svg',
    loading: 'pin-loading.svg',
  },
  clusterFillColor: '#3C951F',
  icon: '/img/delivery/box.svg',
}
