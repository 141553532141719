import { mapDataToModel } from '@nsf/core/Mapper.js'

export const getDefaultTotalRating = () => ({
  count: 0,
  avg: 0,
  stars: 0,
})

export const mapDataToReview = (data, translations = {}) => mapDataToModel(data, {
  ...translations,
})

export const mapDataToTotalRating = (data, translations = {}) => mapDataToModel(data, {
  key: ['rating', Number],
  doc_count: ['count', Number],
  ...translations,
})
export const mapDataToProductsTotalRating = (data, translations = {}) => mapDataToModel(data, {
  key: ['productId', Number],
  doc_count: ['count', Number],
  avg: (avgRating) => avgRating.value,
  stars: (stars) => mapDataToTotalRating(stars.buckets),
  ...translations,
})

const sortMapping = {
  'created_at': 'date',
  'ratings.value': 'rating',
}

const rangeMapping = {
  '4,5': 'positive',
  '3,3': 'neutral',
  '1,2': 'negative',
}

export const setReviewServicePayload = (sku, paginationOffset, sort, range) => {
  const payload = {
    sku,
    limit: 20,
    offset: paginationOffset * 20,
    filter: {},
    sort: {},
  }

  if (sort) {
    const [sortField, sortOrder] = sort.split(':')
    payload.sort[sortMapping[sortField]] = sortOrder
  }

  if (range) {
    const rangeValue = range.split(',').slice(1)
      .join(',')
    if (rangeMapping[rangeValue]) {
      payload.filter.rating = rangeMapping[rangeValue]
    }
  } else {
    delete payload.filter.rating
  }

  return payload
}

export const mapStarsToReview = (stars) => [
  { rating: 1, count: stars['1_star_reviews'] },
  { rating: 2, count: stars['2_star_reviews'] },
  { rating: 3, count: stars['3_star_reviews'] },
  { rating: 4, count: stars['4_star_reviews'] },
  { rating: 5, count: stars['5_star_reviews'] },
]

export const mapReviewsToProduct = (product, reviews) => {
  reviews.forEach((review) => {
    if (review.sku === product.sku) {
      product.rating = {
        avg: review.average_rating,
        count: review.reviews_count.total_reviews,
        stars: mapStarsToReview(review.reviews_count),
      }
    }
  })
  return product
}

export const mapReviewsToProducts = (products, reviews) => {
  products.forEach((product) => {
    mapReviewsToProduct(product, reviews)
  })
  return products
}
