<template>
  <div
    v-if="isStarted() && !isFinished()"
    class="countdown-widget"
    :class="{
      'countdown-widget-dark': darkMode,
    }"
  >
    <div>
      <table>
        <tbody>
          <tr>
            <td><strong>{{ formatValue(days) }}</strong></td>
            <td><span>:</span></td>
            <td><strong>{{ formatValue(hours) }}</strong></td>
            <td><span>:</span></td>
            <td><strong>{{ formatValue(minutes) }}</strong></td>
            <td><span>:</span></td>
            <td><strong>{{ formatValue(seconds) }}</strong></td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>{{ $t('countdown.days') }}</td>
            <td>&#160;</td>
            <td>{{ $t('countdown.hours') }}</td>
            <td>&#160;</td>
            <td>{{ $t('countdown.minutes') }}</td>
            <td>&#160;</td>
            <td>{{ $t('countdown.seconds') }}</td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</template>

<script>

import dayjs from 'dayjs'

export default {
  name: 'Countdown',

  props: {
    item: {
      type: Object,
      default: () => ({}),
    },

    data: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    const data = {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      startDate: null,
      endDate: null,
      timeout: null,
      darkMode: false,
    }

    if (this.item.settings) {
      const { settings } = this.item
      const startDtString = settings.startDatetime
      const endDtString = settings.endDatetime

      data.startDate = dayjs(new Date(startDtString).getTime())
      data.endDate = dayjs(new Date(endDtString).getTime())
      data.darkMode = settings.darkMode === 'true'
      return data
    }

    // new way of reading params
    if (this.data?.children[0]?.['start-datetime']) {
      const child = this.data.children[0]
      const startDtString = child['start-datetime']
      const endDtString = child['end-datetime']

      data.startDate = dayjs(new Date(startDtString).getTime())
      data.endDate = dayjs(new Date(endDtString).getTime())
      data.darkMode = child.darkmode === 'true'
    } else {
      const parts = this.data?.class?.split('-')

      if (parts.length >= 3) {
        try {
          data.startDate = dayjs(parts[1])
          data.endDate = dayjs(parts[2])
        } catch (e) {
          data.startDate = null
          data.endDate = null
        }
      }

      if (parts.length >= 4) {
        data.darkMode = parts[3] === 'dark'
      }
    }

    return data
  },

  mounted() {
    this.timeout = window.setInterval(this.countdown, 1000)
  },

  beforeDestroy() {
    if (this.timeout) {
      window.clearInterval(this.timeout)
    }
  },

  methods: {

    formatValue(value) {
      if (value < 10) {
        return (`0${value}`)
      }
      return value
    },

    isStarted() {
      if (!this.startDate) {
        return false
      }

      const date = dayjs()
      return date.diff(this.startDate, 'seconds') > 0
    },

    isFinished() {
      if (!this.endDate) {
        return false
      }

      const date = dayjs()
      return date.diff(this.endDate, 'seconds') > 0
    },

    countdown() {
      let date = dayjs()

      this.days = this.endDate.diff(date, 'days')
      if (this.days > 0) {
        date = dayjs(date).add(this.days, 'd')
      }

      this.hours = this.endDate.diff(date, 'hours')
      if (this.hours > 0) {
        date = dayjs(date).add(this.hours, 'h')
      }

      this.minutes = this.endDate.diff(date, 'minutes')
      if (this.minutes > 0) {
        date = dayjs(date).add(this.minutes, 'm')
      }

      this.seconds = this.endDate.diff(date, 'seconds')
    },
  },
}
</script>

<style lang="scss" scoped>
.countdown-widget {
  text-align: center;
  padding: 24px 0 16px 0;
  table {
    margin: 0 auto;
    tbody {
      td {
        min-width: 24px;
        text-align: center;
        padding: 0;
        vertical-align: bottom;
        > span {
          font-size: 1.8em;
          display: inline-block;
          padding-bottom: 6px;
        }
        > strong {
          font-size: 3em;
          line-height: .9;
        }
      }
    }
    tfoot {
      td {
        text-align: center;
        font-weight: bold;
        font-size: 0.8em;
        padding: 0;
        text-transform: uppercase;
        line-height: .9;
      }
    }
  }
}
.countdown-widget-dark {
  background: #000;
  color: #9DDBD7;
  > div {
    display: inline-block;
    padding: 12px 24px;
    box-shadow: 0 0 10px 0 #0e8d88, inset 0 0 40px 0 #0e8d88;
    border: 3px solid #9DDBD7;
    border-radius: 20px;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 2px solid #22696C;
      border-radius: 16px;
    }
  }
}
</style>
