<template>
  <div
    v-if="isMounted"
    :class="{
      'login-button-widget': true,
      'hidden': isHidden,
      'alignment-center': alignment === 'center',
      'alignment-right': alignment === 'right',
    }"
  >
    <v-button @click="onClickLogIn">
      {{ caption }}
    </v-button>
  </div>
</template>

<script>
import HasUser from '@nsf/my-account/mixins/HasUser.js'
import Button from '@nsf/ui-library/components/atoms/Button.vue'

export default {
  name: 'VLoginButtonWidget',

  components: {
    'v-button': Button,
  },

  mixins: [HasUser],

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      isMounted: false,
    }
  },

  computed: {
    alignment() {
      return this.item.settings?.horizontalAlignment || this.data?.children?.[0]?.alignment
    },

    caption() {
      return this.item.settings?.caption || this.data?.children?.[0]?.caption || this.$t('login.Sign in')
    },

    isHidden() {
      return !this.isInitialized || this.isLoggedIn
    },
  },

  mounted() {
    this.isMounted = true
  },

  methods: {
    onClickLogIn() {
      const button = document.getElementById('login-btn-header')

      if (button) {
        button.click()
      }
    },
  },
}
</script>

<style>
.login-button-widget {
  margin: 15px 0;
}

.login-button-widget.alignment-center {
  text-align: center;
}

.login-button-widget.alignment-right {
  text-align: right;
}

.login-button-widget.hidden {
  display: none;
}
</style>
