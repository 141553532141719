import { defineAppConfig } from '@nsf/use/composables/defineAppConfig.js'
import DEFAULT from './DEFAULT.mjs'
import DRMAX_BOX from './DRMAX_BOX.mjs'
import PHARMACY from './PHARMACY.mjs'
import config from '../index.mjs'
import map from './map.mjs'

export default defineAppConfig({
  mapConfig: {
    ...config,
    ...map,
  },
  placePrototypes: {
    DEFAULT,
    DRMAX_BOX,
    PHARMACY,
  },
})
