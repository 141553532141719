<template>
  <div>
    <div v-if="label || optional || required" class="input-title">
      <VLabel v-if="label" :id="id">
        {{ label }}
        <VOptional v-if="optional" :optional="optional" />
        <VRequired v-if="required" />
      </VLabel>
    </div>

    <div>
      <div class="position-relative">
        <div v-if="leadingIcon" class="leading-icon">
          <slot name="leadingIcon" />
        </div>
        <VInput
          v-bind="$attrs"
          :value="innerValue"
          :type="!isPasswordVisible ? type : 'text'"
          :leading-icon="leadingIcon"
          :trailing-icon="trailingIcon"
          :is-valid="isValid"
          :is-shifted="isShifted"
          v-on="$listeners"
          @blur="onBlur"
        />
        <div class="trailing-icon" :class="{ pointer: isPassword, showing: isPasswordVisible }" @click="showPassword">
          <svg
            v-if="isPassword"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Trailing icon">
              <path
                id="Vector"
                d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17ZM12 9C10.34 9 9 10.34 9 12C9 13.66 10.34 15 12 15C13.66 15 15 13.66 15 12C15 10.34 13.66 9 12 9Z"
              />
            </g>
          </svg>

          <slot v-else name="trailingIcon" />
        </div>
      </div>
      <slot name="infoBellow" />

      <VError v-if="isValid === false && errors.length" :errors="errors" />
    </div>
    <VAdditional v-if="additional">
      {{ additional }}
    </VAdditional>
  </div>
</template>

<script>
import VLabel from '@nsf/ui-library/components/ions/Label.vue'
import VInput from '@nsf/ui-library/components/atoms/Input.vue'
import VError from '@nsf/ui-library/components/ions/Error.vue'
import VRequired from '@nsf/ui-library/components/ions/Required.vue'
import VOptional from '@nsf/ui-library/components/ions/Optional.vue'
import VAdditional from '@nsf/ui-library/components/ions/Additional.vue'

export default {
  components: {
    VLabel,
    VInput,
    VError,
    VRequired,
    VOptional,
    VAdditional,
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: '',
    },
    value: {
      type: null,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    label: {
      type: String,
      default: '',
    },
    optional: {
      type: String,
      default: null,
    },
    additional: {
      type: String,
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    validator: {
      type: Function,
      default: null,
    },
    errors: {
      type: Array,
      default: () => ([]),
    },
  },
  data() {
    return {
      innerValue: this.value,
      showError: false,
      shouldValidate: false,
      isPasswordVisible: false,
    }
  },
  computed: {
    trailingIcon() {
      return !!this.$slots.trailingIcon
    },

    leadingIcon() {
      return !!this.$slots.leadingIcon
    },

    isShifted() {
      return this.validator && (this.trailingIcon || this.isPassword)
    },

    isValid() {
      if (typeof this.validator === 'function' && this.shouldValidate) {
        return this.validator(this.innerValue)
      }

      return null
    },

    isPassword() {
      return this.type === 'password'
    },
  },

  watch: {
    value: {
      handler(val) {
        this.innerValue = val
      },
    },
    innerValue: {
      handler(val) {
        this.innerValue = val
        this.$emit('input', val)
      },
    },
  },
  methods: {
    onBlur() {
      this.shouldValidate = true
      this.showError = !this.isValid

      this.$emit('blur', this.innerValue)
    },

    showPassword() {
      if (this.isPassword) {
        this.isPasswordVisible = !this.isPasswordVisible
      }
    },
  },
}
</script>

<style scoped lang="scss">
/* stylelint-disable selector-pseudo-element-no-unknown */

::v-deep .error-wrapper {
  margin-top: -4px;
  border: 1px solid $red-500;
  border-top: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.input-title {
  display: flex;
  align-items: center;
  margin: 0 2px 10px;
}

.leading-icon {
  position: absolute;
  top: 50%;
  left: 8px;
  z-index: 1;
  width: 24px;
  height: 24px;
  transform: translateY(-50%);

  svg {
    width: 100%;
    height: 100%;
    color: $gray-300;
  }
}

.trailing-icon {
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 1;
  width: 24px;
  height: 24px;
  color: $gray-300;
  transform: translateY(-50%);

  svg {
    width: 100%;
    height: 100%;
    color: $gray-300;
    fill: $gray-300;
  }

  &.pointer {
    cursor: pointer;
  }

  &.showing svg {
    fill: $gray-200;
  }
}
</style>
