<template>
  <div
    class="publitas-widget"
  >
    <div :id="`publitas-embed-${id}`" />
    <script
      data-cfasync="false"
      data-height="undefined"
      :data-publication="url"
      :data-publication-aspect-ratio="ratio"
      data-responsive="true"
      data-width="undefined"
      :data-wrapper-id="`publitas-embed-${id}`"
      publitas-embed
      src="https://view.publitas.com/embed.js"
      type="text/javascript"
    />
  </div>
</template>

<script>

export default {
  name: 'PublitasWidget',

  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

    item: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      id: '',
    }
  },

  computed: {
    url() {
      return this.item.settings?.url || this.data?.children[0]?.url
    },

    ratio() {
      if (this.item.settings?.ratio) {
        return this.item.settings.ratio
      }
      return this.data?.children[0]?.ratio ? this.data.children[0].ratio : undefined
    },
  },

  created() {
    this.id = (Math.random() + 1).toString(36)
      .substring(7)
  },
}
</script>
