/* eslint-disable no-unreachable */
/* eslint-disable no-underscore-dangle */

import { mapGetters } from 'vuex'
import {
  formatPrice, getCurrency,
} from '@nsf/utils/PriceUtils.js'
import { useAppConfig } from '@nsf/use/composables/useAppConfig.js'
import { image, normalizeUrl } from '@nsf/utils/UrlUtils.js'
import { format } from '@nsf/utils/DateUtils.js'
import {
  extractRawTextFromHtml, isArray, isObject, toCamel,
} from '@nsf/core/Utils.js'
import HasUser from '@nsf/my-account/mixins/HasUser.js'
import PlacementData from '@nsf/catalog/mixins/PlacementData.js'
import {
  isInStock,
  isTemporaryUnavailable,
  isPermanentlySoldOut,
  isPreStock,
  allowNegativeStock,
  isAdvertisingProduct,
  isMarketplaceProduct,
} from '@nsf/catalog/utils/StockUtils.js'
import { useRuntimeConfig } from '@nsf/use/composables/useRuntimeConfig.js'

export const LOADER_LABEL_MAGENTO = 'Magento'
export const LOADER_LABEL_ALGOLIA = 'Algolia'
export const LOADER_LABEL_SEARCH = 'Search'
export const LOADER_LABEL_MEIRO = 'Meiro'
export const LOADER_LABEL_PERSOO = 'Persoo'

export const EVENT_ORIGIN_PRODUCT_CLICK = 'product-click'
export const EVENT_ORIGIN_ADD_TO_CART = 'add-to-cart'

const {
  base: {
    currency,
  },
  catalog: {
    product: {
      reviewsEnabled,
      shortDescriptionShowedOn,
      isAttributeSetShowed,
      defaultMaxQty,
      detail: {
        addAttributeSetToAttributes,
        specialDescriptionAttributes,
      },
    },
    catalog: {
      pim,
      showSimpleTemporaryUnavailable,
    },
  },
  rootConfig: {
    global: {
      watchdog,
    },
  },
} = useAppConfig()

const {
  public: {
    reviewServiceEnabled,
  },
} = useRuntimeConfig()

export default {
  mixins: [
    HasUser,
    PlacementData,
  ],

  computed: {
    ...mapGetters({ config: '_base/config/config' }),

    id() {
      return this.product.id || 0
    },

    sku() {
      return this.product.sku || ''
    },

    urlPath() {
      return this.product.urlPath || ''
    },

    // eslint-disable-next-line sort-keys
    name() {
      return [
        this.titleRow1,
        this.titleRow2,
      ].join(' ')
    },

    // eslint-disable-next-line sort-keys
    brandName() {
      return this.product.brandName || ''
    },

    fksp() {
      return this.product.drmaxFksp
    },

    titleRow1() {
      return this.product.drmaxTitleRow1 || ''
    },

    // eslint-disable-next-line sort-keys
    groupTitleRow1() {
      return this.categoryProductGroupData.drmaxTitleRow1 || ''
    },

    // eslint-disable-next-line sort-keys
    groupMetaTitle() {
      return this.categoryProductGroupData.metaTitle || ''
    },

    // eslint-disable-next-line sort-keys
    groupMetaDescription() {
      return this.categoryProductGroupData.metaDescription || ''
    },

    titleRow2() {
      return this.product.drmaxTitleRow2 || ''
    },

    // eslint-disable-next-line sort-keys
    salesRules() {
      return this.product.salesRules || []
    },

    // eslint-disable-next-line sort-keys
    loginMotivationSalesRules() {
      if (this.myAccountSorpEnabled && this.config.drmaxSorpPromoPriceBoxEnabled) {
        // skip all when full account is logged in
        if (this.isLoggedIn && this.isFullUserAccount) {
          return []
        }

        // promotion for full account when user is limited only
        if (this.isLoggedIn && this.isLimitedUserAccount) {
          return this.salesRules
            .filter((rule) => [
              rule.drmaxValidFor.includes('logged_in_full'),
              !rule.drmaxValidFor.includes('logged_in_limited'),
            ].every(Boolean)) || []
        }

        // promotion for full or limited account when set on not logged in
        if (!this.isLoggedIn) {
          return this.salesRules
            .filter((rule) => [
              rule.drmaxValidFor.includes('logged_in_full') || rule.drmaxValidFor.includes('logged_in_limited'),
              !rule.drmaxValidFor.includes('not_logged_in'),
            ].every(Boolean)) || []
        }
      }

      // promotion for regular registration
      // legacy solution for general group (logged in state is handled in loginMotivation component)
      return this.salesRules.filter((rule) => rule.drmaxValidFor.includes('logged_in_general')
        && rule.drmaxPromoTitle !== '' && rule.drmaxPromoText !== '' && rule.drmaxPromoLegalText !== '')
        || []
    },

    // eslint-disable-next-line sort-keys
    regularSalesRules() {
      if (this.myAccountSorpEnabled && this.config.drmaxSorpPromoPriceBoxEnabled && this.isLoggedIn) {
        const filterForAccountType = this.isFullUserAccount ? 'logged_in_full' : 'logged_in_limited'

        return this.salesRules
          .filter((rule) => [
            rule.drmaxValidFor.includes(filterForAccountType),
          ].every(Boolean)) || []
      }

      return this.salesRules.filter((rule) => rule.drmaxValidFor.includes('not_logged_in')) || []
    },

    // eslint-disable-next-line sort-keys
    isOnSale() {
      return this.isDiscountedBySalesOff || this.isDiscountedByOriginalPrice
    },

    // eslint-disable-next-line sort-keys
    isDiscountedBySalesOff() {
      return this.product.regularPrice && (this.product.regularPrice > this.finalPrice)
    },

    // eslint-disable-next-line sort-keys
    isDiscountedByOriginalPrice() {
      return this.originalPrice && (this.originalPrice > this.finalPrice) // original price introduced in USER STORY 2544!
    },

    isRxProduct() {
      return !!this.product.drmaxRxProduct
    },

    // eslint-disable-next-line sort-keys
    isMasterGroupProduct() {
      return Object.entries(this.categoryProductGroupData).length > 0
    },

    // price which ends up as the "striketrough" price to display in UI
    regularPrice() {
      return Math.max( // take the highest one
        this.originalPrice,
        this.product.regularPrice || 0,
        this.product.regularPriceLogged || 0,
      )
    },

    regularPriceF() {
      return formatPrice(
        this.regularPrice,
        this.currency,
        true,
      ) // currency sign is added in formatPrice
    },

    regularPriceValue() {
      return formatPrice(
        this.regularPrice,
        this.currency,
        false,
      )
    },

    lowestPriceF() {
      return this.drmaxLowestPrice30ManualF || this.drmaxLowestPrice30F
    },

    // price forced to be the one to used before (overweights the regular price if provided)
    // eslint-disable-next-line sort-keys
    originalPrice() {
      return +this.product.drmaxOriginalPrice || 0
    },

    originalPriceF() {
      return formatPrice(
        this.originalPrice,
        this.currency,
        true,
      )
    },

    // price which ends up as the "main bold" price to display in UI
    // eslint-disable-next-line sort-keys
    finalPrice() {
      if (!this.isFinalPrice) {
        return 0
      } // has no finalPrice -> can't buy product!

      // if some logged price is already lower than finalPrice, then
      if (this.isLoggedIn) {
        // if SORP enabled and user account is full
        if (this.myAccountSorpEnabled && this.isFullUserAccount && this.priceForFullAvailable) {
          return this.product.finalPriceFull
        }

        // if SORP enabled and user account is limited
        if (this.myAccountSorpEnabled && this.isLimitedUserAccount && this.priceForLimitedAvailable) {
          return this.product.finalPriceLimited
        }

        if (this.priceForLoggedAvailable) {
          return this.product.finalPriceLogged
        }
      } // show price for logged in customers

      return this.product.finalPrice // show "normal" final price
    },

    // at least one of final prices exists
    isFinalPrice() {
      return !!this.product.finalPrice || !!this.product.finalPriceLogged
    },

    // eslint-disable-next-line sort-keys
    hasBetterFullPriceAvailable() {
      const { finalPrice, finalPriceFull, finalPriceLimited } = this.product

      if (!this.priceForFullAvailable) {
        return false
      }

      // both full/limited prices are set, but full is better
      if (this.priceForLimitedAvailable) {
        return finalPriceFull < finalPriceLimited
      }

      // only full account price is set and its better than final price
      return finalPriceFull < finalPrice
    },

    // eslint-disable-next-line sort-keys
    finalPriceF() {
      return formatPrice(
        this.finalPrice,
        this.currency,
        false,
      ) // currency is added manually in vue template
    },

    minGroupPriceF() {
      return this.categoryProductGroupData.finalPrice?.length > 0
        ? formatPrice(Math.min(...this.categoryProductGroupData.finalPrice), this.currency, false)
        : ''
    },

    priceForLoggedAvailable() {
      const { finalPrice, finalPriceLogged } = this.product
      return !!finalPriceLogged && finalPriceLogged < finalPrice
    },

    priceForLoggedApplied() {
      const { finalPriceLogged } = this.product
      return [
        this.isLoggedIn,
        this.priceForLoggedAvailable,
        this.finalPrice === finalPriceLogged,
      ].every(Boolean)
    },

    priceForLimitedAvailable() {
      const { finalPrice, finalPriceLimited } = this.product
      return !!finalPriceLimited && finalPriceLimited < finalPrice
    },

    priceForLimitedApplied() {
      const { finalPriceLimited } = this.product
      return [
        this.isLoggedIn,
        this.isLimitedUserAccount,
        this.priceForLimitedAvailable,
        this.finalPrice === finalPriceLimited,
      ].every(Boolean)
    },

    priceForFullAvailable() {
      const { finalPrice, finalPriceFull } = this.product
      return !!finalPriceFull && finalPriceFull < finalPrice
    },

    priceForFullApplied() {
      const { finalPriceFull } = this.product
      return [
        this.isLoggedIn,
        this.isFullUserAccount,
        this.priceForFullAvailable,
        this.finalPrice === finalPriceFull,
      ].every(Boolean)
    },

    // used to show box with registration incentive to have better customer prices
    showLoggedPriceInfo() {
      // can be disabled globally by configuring
      if (!this.config.drmaxPremiumPriceTogglerEnabled) {
        return false
      }
      // SORP my-account and promo flag is enabled,
      // and better price is available for limited or full users
      if ([
        this.myAccountSorpEnabled,
        this.config.drmaxSorpPromoPriceBoxEnabled, // enables UI parts of new SORP functionality regardless myAccountSorpEnabled
        this.priceForLimitedAvailable || this.priceForFullAvailable,
      ].every(Boolean)) {
        return true
      }
      // SORP is disabled and logged-in price is available
      if (this.priceForLoggedAvailable) {
        return true
      }
      return false
    },

    // eslint-disable-next-line sort-keys
    discountAbs() {
      return this.regularPrice - this.finalPrice || 0
    },

    discountAbsF() {
      return formatPrice(
        this.discountAbs,
        this.currency,
      )
    },

    discountRel() {
      return Math.round((this.discountAbs / this.regularPrice) * 100) / 100 || 0
    },

    discountRelF() {
      return `${Math.round(this.discountRel * 100)}%`
    },

    // eslint-disable-next-line sort-keys
    currency() {
      return currency
    },

    currencyF() {
      return getCurrency(
        this.finalPrice,
        this.currency,
      )
    },

    mpPriceF() {
      return formatPrice(
        this.finalPrice / parseFloat(this.product.drmaxMpCoefficient ?? 1),
        this.currency,
        true,
        [2, 0],
      )
    },

    shortDescription() {
      return extractRawTextFromHtml(this.product.shortDescription) || ''
    },

    teaserDescription() {
      return extractRawTextFromHtml(this.product.drmaxTeaserDescription) || ''
    },

    // eslint-disable-next-line sort-keys
    groupTeaserDescription() {
      return extractRawTextFromHtml(this.categoryProductGroupData.drmaxTeaserDescription) || ''
    },

    // eslint-disable-next-line sort-keys
    attributeSetId() {
      return this.product.attributeSetId || ''
    },

    attributeSetLabel() {
      return this.product.attributeSetLabel || ''
    },

    displayRating() {
      return !this.isMasterGroupProduct && this.rating?.count && (reviewsEnabled || reviewServiceEnabled)
    },

    rating() {
      return this.product.rating ?? null
    },

    // eslint-disable-next-line sort-keys
    categories() {
      return this.product._categories || []
    },

    loaderLabel() {
      return this.product.loaderLabel || LOADER_LABEL_MAGENTO
    },

    /**
     * Can return 'null', check before using!
     */
    mainCategory() {
      return this.product._mainCategory || null
    },

    mainCategoryUrlPath() {
      if (this.product.drmaxMainCategoryUrlPath) {
        return this.product.drmaxMainCategoryUrlPath
      }
      return this.categories.length ? this.categories[0].urlPath : null
    },

    // eslint-disable-next-line sort-keys
    hierarchicalCategories() {
      return this.product._hierarchicalCategories || []
    },

    // eslint-disable-next-line sort-keys
    description() {
      return this.product.description || []
    },

    descriptionConfig() {
      return this.product.drmaxProductDetailConfig?.tabs || []
    },

    // eslint-disable-next-line sort-keys
    attributes() {
      const attributes = [...this.product._attributes || []]

      if (addAttributeSetToAttributes) {
        attributes.push({
          label: this.$t('global.catalog.Attribute set name'),
          usedInProductListing: true,
          value: this.attributeSetLabel,
        })
      }

      return attributes
    },

    // eslint-disable-next-line sort-keys
    attachments() {
      return this.product._productAttachments || []
    },

    bundles() {
      return this.product._bundles || []
    },

    // eslint-disable-next-line sort-keys
    availability() {
      return this.product.availability || ''
    },

    enabledCatalog() {
      return this.$store.state.enabled && this.$store.state.enabledCatalog
    },

    productGroup() {
      return this.product._productGroup || {}
    },

    // eslint-disable-next-line sort-keys
    categoryProductGroupData() {
      return this.product.drmaxProductGroupData || {}
    },

    productVariants() {
      return this.productGroup.productVariants || []
    },

    // eslint-disable-next-line sort-keys
    hasProductVariants() {
      return this.productVariants.length > 0
    },

    // eslint-disable-next-line sort-keys
    badges() {
      const badges = []

      let discountValue
      let discountUnit

      switch (this.product.drmaxLabelDiscountType) {
        case 1:
          discountUnit = '%'
          discountValue = parseFloat(this.product.drmaxLabelDiscountPercent)
            .toFixed(0)
          break
        case 2:
          discountUnit = ''
          discountValue = formatPrice(
            this.product.drmaxLabelDiscountAmount,
            this.currency,
          )
          break
        default:
          discountUnit = ''
          discountValue = ''
          break
      }

      if (discountValue) {
        badges.push({
          text: this.$t(
            'global.catalog.Sale',
            [discountValue],
          ) + discountUnit,
        })
      }

      if (this.product.drmaxLabelsAggregated?.length) {
        for (const badge of this.product.drmaxLabelsAggregated) {
          if (isObject(badge)) {
            switch (badge.drmaxLabelType) {
              case 'text':
                badges.push({
                  priority: badge.drmaxLabelPriority,
                  text: badge.label,
                })
                break
              case 'icon_freedelivery':
                badges.push({
                  priority: badge.drmaxLabelPriority,
                  text: '/images/icons/icons.svg#free-delivery',
                })
                break
              case 'icon_gift':
                badges.push({
                  priority: badge.drmaxLabelPriority,
                  text: '/images/icons/icons.svg#gift',
                })
                break

              default:
                badges.push({
                  priority: badge.drmaxLabelPriority,
                  text: badge.label,
                })
                break
            }
          }
        }
      }
      badges.sort((
        a, b,
      ) => a.priority - b.priority)
      return badges
    },

    breadcrumbs() {
      return [
        {
          link: '/',
          trans: 'global.breadcrumbs.Home',
        },
        ...this.hierarchicalCategories.map((category) => ({
          link: category.urlPath,
          title: category.name,
        })),
        {
          link: this.urlPath,
          title: this.titleRow1,
        },
      ]
    },

    metaTitle() {
      return this.groupMetaTitle || this.product.metaTitle || `${this.titleRow1} ${this.titleRow2}` || ''
    },

    // eslint-disable-next-line sort-keys
    metaDescription() {
      return this.groupMetaDescription || this.product.metaDescription || this.shortDescription || ''
    },

    metaKeywords() {
      return this.product.metaKeywords || ''
    },

    // eslint-disable-next-line sort-keys
    isInStock() {
      return isInStock(this.product)
    },

    isPermanentlySoldOut() {
      return isPermanentlySoldOut(this.product)
    },

    // eslint-disable-next-line sort-keys
    isAdvertisingProduct() {
      return isAdvertisingProduct(this.product)
    },

    // eslint-disable-next-line sort-keys
    allowNegativeStock() {
      return allowNegativeStock(this.product)
    },

    isTemporaryUnavailable() {
      return isTemporaryUnavailable(this.product)
    },

    // eslint-disable-next-line sort-keys
    isPreStock() {
      return isPreStock(this.product)
    },

    // eslint-disable-next-line sort-keys
    isMarketplaceProduct() {
      return isMarketplaceProduct(this.product)
    },

    seller() {
      return this.product.seller || {}
    },

    stockAvailabilityStatusText() {
      // eslint-disable-next-line max-len
      if (this.product.drmaxPimStatus === pim.temporaryUnavailable || (!this.product?.drmaxStockAvailabilityTextValue && !this.isMarketplaceProduct)) {
        return this.$t('global.Temporary unavailable')
      }

      if (!this.isInStock) {
        return this.$t('global.catalog.Out of stock')
      }

      if (this.isInStock && this.isMarketplaceProduct) {
        return this.$t('global.catalog.In stock at')
      }

      return this.product.drmaxStockAvailabilityTextValue
    },

    // eslint-disable-next-line sort-keys
    maxQty() {
      const saleableQuantity = this.product?.drmaxStockSalableQty || 0
      const maxQty = this.product?.maxSaleQty || defaultMaxQty

      if (this.allowNegativeStock) {
        return maxQty
      }

      return Math.min(
        saleableQuantity,
        maxQty,
      ) // negative stock not allowed
    },

    showSimpleUnavailable() {
      return showSimpleTemporaryUnavailable
    },

    // eslint-disable-next-line sort-keys
    isWatchdogEnabled() {
      return this.config.drmaxBackInStockNotificationAllow
    },

    priceAlertEnabled() {
      return this.config.drmaxPriceNotificationAllow
    },

    // eslint-disable-next-line sort-keys
    isForceLoginEnabled() {
      return watchdog.forceLoginEnabled
    },

    // eslint-disable-next-line sort-keys
    hasCustomOptions() {
      return (isArray(this.product.customOptions) && this.product.customOptions.length > 0)
    },

    // eslint-disable-next-line sort-keys
    drmaxFreeShippingThreshold() {
      return {
        icon: this.product.drmaxFreeShippingThreshold?.icon ? `/images/icons/icons.svg#${this.product.drmaxFreeShippingThreshold?.icon}` : '/images/icons/icons.svg#truck',
        text: this.product.drmaxFreeShippingThreshold?.text ?? this.$t('catalog.product.Free shipping over'),
        value: this.product.drmaxFreeShippingThreshold?.value ?? null,
      }
    },

    // eslint-disable-next-line sort-keys
    drmaxDescIntroduction() {
      return this.product.drmaxDescIntroduction || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescDosage() {
      return this.product.drmaxDescDosage || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescComposition() {
      return this.product.drmaxDescComposition || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescUse() {
      return this.product.drmaxDescUse || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescNotice() {
      return this.product.drmaxDescNotice || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxAbcd() {
      return this.product.drmaxAbcd || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescTherapeuticIndications() {
      return this.product.drmaxDescTherapeuticIndications || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescActive() {
      return this.product.drmaxDescActive || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescTexture() {
      return this.product.drmaxDescTexture || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescForWhom() {
      return this.product.drmaxDescForWhom || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescBenefits() {
      return this.product.drmaxDescBenefits || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescExcipients() {
      return this.product.drmaxDescExcipients || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescCollateralEffects() {
      return this.product.drmaxDescCollateralEffects || ''
    },

    drmaxDescWarnings() {
      return this.product.drmaxDescWarnings || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescInteractions() {
      return this.product.drmaxDescInteractions || ''
    },

    drmaxDescUndesirableEffects() {
      return this.product.drmaxDescUndesirableEffects || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescOverdose() {
      return this.product.drmaxDescOverdose || ''
    },

    drmaxDescPregnancy() {
      return this.product.drmaxDescPregnancy || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescConservation() {
      return this.product.drmaxDescConservation || ''
    },

    drmaxDescPharmacistAdvice() {
      return this.product.drmaxDescPharmacistAdvice || ''
    },

    drmaxDescProductFaq() {
      // Note: toCamel('drmax_desc_product_FAQ') === 'drmaxDescProduct_FAQ'
      return this.product.drmaxDescProduct_FAQ || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescDriveEffect() {
      return this.product.drmaxDescDriveEffect || ''
    },

    drmaxDescForm() {
      return this.product.drmaxDescForm || ''
    },

    drmaxDescOvodeposition() {
      return this.product.drmaxDescOvodeposition || ''
    },

    drmaxDescSecurityInSpecies() {
      return this.product.drmaxDescSecurityInSpecies || ''
    },

    drmaxDescWaitingTimes() {
      return this.product.drmaxDescWaitingTimes || ''
    },

    // eslint-disable-next-line sort-keys
    drmaxDescAboutBrand() {
      return this.product.drmaxDescAboutBrand || ''
    },

    isAttributeSetShowed() {
      return isAttributeSetShowed
    },

    shortDescriptionShowedOn() {
      return shortDescriptionShowedOn || []
    },

    // eslint-disable-next-line sort-keys
    productAlerts() {
      return this.product.alerts ?? []
    },

    // eslint-disable-next-line sort-keys
    isAdultsOnly() {
      return this.product?.drmaxAdultsOnly === 1
    },

    // eslint-disable-next-line sort-keys
    advPriceIsReservationAllowed() {
      return this.product.advPriceIsReservationAllowed
    },

    /**
     * Returns information whether a product has a short expired date
     * for the moment, a simplification has been applied - if a date exists, it means that it is short
     * @summary Returns information whether a product has a short expired date
     * @returns {boolean}
     */
    hasShortExpiredDate() {
      return !!this.product.drmaxPlExpireDate
    },

    // eslint-disable-next-line sort-keys
    descriptionAttributes() {
      return this.product?.descriptionAttributes?.map((attribute) => toCamel(attribute))
        || specialDescriptionAttributes?.map((attribute) => toCamel(attribute)) || []
    },

    isRx() {
      return !!this.product?.drmaxRx
    },

    schemaImage() {
      return this.thumbnail({
        w: 200,
        h: 200,
      })
    },

    // eslint-disable-next-line sort-keys
    depositFee() {
      return this.product.drmaxBoundProductsPrice
    },

    // eslint-disable-next-line sort-keys
    allowRichContent() {
      return this.product.drmaxAllowRichContent || false
    },

    specialFromDateF() {
      return format(
        this.product.specialFromDateManual || this.product.specialFromDate,
        'DD.MM.YYYY',
      )
    },

    // eslint-disable-next-line sort-keys
    drmaxLowestPrice30F() {
      return +this.product.drmaxLowestPrice30?.toFixed(2) || 0
    },

    drmaxLowestPrice30ManualF() {
      return +this.product.drmaxLowestPrice30Manual?.toFixed(2) || 0
    },

    tags() {
      return this.product.tags || []
    },
  },

  methods: {
    thumbnail(opts = {}) {
      return image(this.thumbnailOpts(opts))
    },

    thumbnailOpts(opts = {}) {
      const m2 = `/media/catalog/product${normalizeUrl(this.product.thumbnail)}`
      return {
        m2,
        fit: 'contain',
        ...opts,
      }
    },

    getProductGtmList(product, list) {
      const loaderLabel = product.loaderLabel ?? ''
      const loaderType = product.loaderType ?? ''
      return loaderLabel && loaderType ? `${loaderLabel} - ${loaderType}` : list
    },
  },
}
